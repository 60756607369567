import { graphql, Link, PageProps, useStaticQuery } from 'gatsby'
import Img from 'gatsby-image'
import { darken } from 'polished'
import React from 'react'
import styled, { useTheme } from 'styled-components'
import { Button } from '../../components/Button'
import Container from '../../components/Container'
import Footer from '../../components/Footer/Footer'
import { Site } from '../../components/Site'
import { Main } from '../../components/Styled'
import { SEO } from '../../SEO'

const whitepapers = [
  {
    heading: 'Getting past “compensation effects”: The importance of monitoring and managing claimant distress',
    description: 'Identifying and managing claimant distress poses challenges within the Insurance industry. This White Paper explores how AI-driven conversational analytics technology provides effective solutions to enhance the claimant experience.',
    href: '/whitepapers/daisee-compensation-effects',
    id: 'compeffect',
  },
  {
    heading: 'Using AI to extract insights from customer conversations in Financial Services',
    description: 'An exploration into how conversational analytics can increase the effectiveness of contact centres in the Financial Services sector by unlocking the actionable information held in customer interaction data.',
    href: '/whitepapers/daisee-using-ai-whitepaper',
    id: 'usingai',
  },
  {
    heading: 'A Complicated Contact Centre Space: daisee brings AI driven growth to DXC Claims Management',
    description: `This White Paper examines the intricate challenges encountered by DXC Claims Management in the rapidly evolving contact centre landscape, while providing insight into the how Daisee's cutting-edge solutions have played a pivotal role in driving growth for DXC Claims Management.`,
    href: '/whitepapers/daisee-dxc-claims',
    id: 'dxc',
  }
]

const Whitepapers: React.FC<PageProps> = props => {
  const theme = useTheme()

  const images = useStaticQuery(graphql`
    query WhitepaperPage {
      dxc: file(relativePath: { eq: "DXC-hero.png" }) {
        childImageSharp {
          fluid(maxWidth: 2000, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      compeffect: file(relativePath: { eq: "comp-effects-hero.png" }) {
        childImageSharp {
          fluid(maxWidth: 2000, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      usingai: file(relativePath: { eq: "using-ai-hero.png" }) {
        childImageSharp {
          fluid(maxWidth: 2000, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `)

  return (
    <Site {...props}>
      <Main>
        <Container>
          <PageContent>
            <h1 className='heading-main'>Daisee White Papers</h1>
            {whitepapers.map(whitepaper => (
              <Link to={whitepaper.href} key={whitepaper.href} style={{ marginBottom: '2rem', display: 'block' }}>
                <div className='whitepaper'>
                  <div className='grid'>
                    <div className='copy'>
                      <div className='inner'>
                        <div className='heading'>{whitepaper.heading}</div>
                        <div className='description'>{whitepaper.description}</div>
                      </div>
                      <a className='button' href={whitepaper.href} >
                        <Button action='Download' btnHeight='auto' bgColor={theme.colors.purple} />
                      </a>
                    </div>
                    <div className='imgwrapper'>
                      <Img
                        style={{ maxHeight: '20rem' }}
                        imgStyle={{ objectFit: 'contain' }}
                        fluid={images[whitepaper.id].childImageSharp.fluid}
                      />
                    </div>
                  </div>
                </div>
              </Link>
            ))}
          </PageContent>
        </Container>
      </Main>
      <Footer />
    </Site>
  )
}

const PageContent = styled.div`
  margin: 9rem 0;

  .heading-main {
    margin: 0;
    font-weight: 500;
    font-size: 2.25rem;
    letter-spacing: -1.5px;
    line-height: 1.2;
    color: ${({ theme }) => theme.colors.purple};
    margin-bottom: 3rem;
  }

  .whitepaper {
    border-radius: 1.5rem;
    background: ${({ theme }) => theme.colors.purple100};
    min-height: 18rem;
    overflow: hidden;
    transition: all 0.25s ease-in;

    &:hover {
      background: ${({ theme }) => darken(0.05, theme.colors.purple100)};
      box-shadow: ${({ theme }) => theme.boxShadow.small};
    }
  }

  .grid {
    display: grid;
    grid-template-columns: 1fr 0.5fr;
    padding-left: 2.5rem;
    height: 100%;
    gap: 1rem;
  }

  .copy {
    display: grid;
    gap: 1rem;
    padding-top: 2.25rem;
    padding-bottom: 2.25rem;
  }

  .inner {
    display: grid;
    gap: 1rem;
    height: fit-content;
  }

  .heading {
    font-weight: 500;
    font-size: 1.75rem;
    letter-spacing: -0.5px;
    line-height: 1.2;
    color: ${({ theme }) => theme.colors.purple};
  }

  .description {
    font-weight: 400;
    font-size: 1.25rem;
    letter-spacing: -0.5px;
    line-height: 1.2;
    color: ${({ theme }) => theme.colors.purple};
  }

  .imgwrapper {
    padding-top: 2.25rem;
  }


  ${({ theme }) => theme.breakpoints.tablet} {
    .whitepaper {
      height: 26rem;
    }
    .grid {
      padding: 0;
      grid-template-columns: 1fr;
    }

    .heading {
      font-size: 1.5rem;
    }

    .description {
      font-size: 1.125rem;
    }

    .copy {
      padding: 1.5rem;
    }
  }
`

export default Whitepapers
